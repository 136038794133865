<script lang="ts">
import { UserInfoEntityDto } from '@/api/dto/user.dto';
import { getUserInfo } from '@/api/user';
import ArrowDown from '@/components/common/icons/ArrowDown.vue'; // dropdown 화살표 (svg 파일로 관리)
import AppConfig, { APP_ENV_TYPE } from '@/constants';
import { THIRD_PARTY_TYPE } from '@/stores/common-store';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { LOADING_TYPE } from '@/types';
import { getApiClient } from '@/utils/apiClient';
import { loadLocalData, removeLocalData, saveLocalData } from '@/utils/common-util';
import { ssoLogin, ssoLogout } from '@/utils/sso-login';
import { loadApiGauge } from '@/utils/utils';
import { computed, defineComponent, nextTick, onMounted, onUpdated, ref, watch } from 'vue';
import { config as gtagConfig } from 'vue-gtag';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { HeaderSelectOption } from '../common/apocSelect';
import HeaderSelect from '../common/HeaderSelect.vue';
import MegaMenuDropdown from '../common/MegaMenuDropdown.vue';
import { goAuthor } from '@/utils/apoc-utils';

export default defineComponent({
	name: 'MegaMenuPlay',
	components: { ArrowDown, HeaderSelect, MegaMenuDropdown },
	setup() {
		const { t } = useI18n({ useScope: 'global' });
		const lang = ref<string>('ko');
		const userInfo = ref<UserInfoEntityDto | null>(null);
		const storeManager = initStore();
		const route = useRoute();
		const router = useRouter();
		const apiClient = computed(() => getApiClient(AppConfig.API_SERVER, storeManager));
		const token = computed(() => loadLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN));
		const user = computed(() => loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER));
		const keyword = ref<string>((route.query.keyword as string) || ''); // 검색창 입력
		const isSearchPage = computed(() => route.path === '/search');

		enum ServiceType { // apoc service
			APOC = 'APOC',
			PLAY = 'PLAY',
			STUDIO = 'STUDIO',
			ASSET = 'ASSET',
			COMM = 'COMM',
		}

		// 현재 서비스 (각 서비스에 맞게 설정 필요)
		const curServiceType = ServiceType.PLAY;

		// 드롭다운 열림 관리 변수
		const isSubMenuVisible = ref<boolean>(false); // sub menu dropdown 열림 변수
		const isSearchVisible = ref<boolean>(route.path === '/search' ? true : storeManager.stateStore.isOpenSearchBar); // search bar dropdown 열림 변수
		const isTabletMenuVisibleType = ref<ServiceType | undefined>(curServiceType); // 현재 서비스 값으로 초기 설정!!!

		// Select - 언어
		const langOptions = [
			{
				value: 'KR',
				label: t('lang.ko'),
				subLabel: t('KO'),
				func: () => {
					changeLang('ko');
				},
			},
			{
				value: 'EN',
				label: t('lang.en'),
				subLabel: t('EN'),
				func: () => {
					changeLang('en');
				},
			},
		];
		const myMenuOptions = ref<HeaderSelectOption[]>([
			{
				value: 'profile-change',
				label: t('layout.header.my.profileChange'),
				router: '/my-profile',
			},
			{
				value: t('layout.header.myPage'),
				label: t('layout.header.myPage'),
				router: '/my-page',
				imageSrc: '/assets/images/common/icons/header/icon_mypage.svg',
			},
			{
				value: t('layout.header.my.myContents'),
				label: t('layout.header.my.myContents'),
				router: '/my-contents',
				imageSrc: '/assets/images/common/icons/header/icon_draw-polygon2.svg',
			},
			{
				value: t('layout.header.my.save'),
				label: t('layout.header.my.save'),
				router: '/my-page?tab=save',
				imageSrc: '/assets/images/common/icons/header/icon_bookmark.svg',
			},
			{
				value: t('layout.header.my.follwing'),
				label: t('layout.header.my.follwing'),
				router: '/my-page?tab=following',
				imageSrc: '/assets/images/common/icons/header/icon_people.svg',
			},
			{
				value: t('layout.header.my.infoChange'),
				label: t('layout.header.my.infoChange'),
				router: '/my-page/edit',
				imageSrc: '/assets/images/common/icons/header/icon_setting.svg',
			},
			{
				value: t('layout.header.my.logout'),
				label: t('layout.header.my.logout'),
				imageSrc: '/assets/images/common/icons/header/icon_logout.svg',
				func: () => {
					ssoLogout(storeManager);
				},
			},
		]);

		const changeLang = (v: string) => {
			lang.value = v;
			saveLocalData(AppConfig.KEYS.CONST.CHANGE_LANG, v);
			window.location.reload();
		};

		const url = {
			[ServiceType.APOC]: AppConfig.BRAND_HOST,
			[ServiceType.PLAY]: AppConfig.FRONT_HOST,
			[ServiceType.STUDIO]: AppConfig.AUTHOR_HOST,
			[ServiceType.ASSET]: AppConfig.ASSET_HOST,
			[ServiceType.COMM]: AppConfig.COMM_HOST,
		};

		// mouse가 영역 안으로 들어오는 경우
		const handleMouseEnter = () => {
			isSubMenuVisible.value = true;
		};

		// mouse가 영역 밖으로 나가는 경우
		const handleMouseLeave = (event: MouseEvent) => {
			const target = event.relatedTarget as Element | null;
			const { clientY, clientX } = event;

			// 마우스가 나가는 대상이 sub-menu-section이 아니면 메뉴를 닫기
			if (target && !target.closest('.sub-menu-section')) {
				isSubMenuVisible.value = false;
			}
			// 마우스가 브라우저 밖을 벗어나면 메뉴를 닫기
			else if (clientY <= 0 || clientX <= 0 || clientX >= window.innerWidth) {
				isSubMenuVisible.value = false;
			}
		};

		// mouse가 영역 밖으로 나가는 경우
		const handleMouseSubLeave = (event: MouseEvent) => {
			const target = event.relatedTarget as Element | null;
			const { clientY, clientX } = event;

			// 마우스가 나가는 대상이 main-menu-section이 아니면 메뉴를 닫기
			if (target && !target.closest('.main-menu-section')) {
				isSubMenuVisible.value = false;
			}
			// 마우스가 브라우저 밖을 벗어나면 메뉴를 닫기
			else if (clientY <= 0 || clientX <= 0 || clientX >= window.innerWidth) {
				isSubMenuVisible.value = false;
			}
		};

		/**
			params
			@service : apoc 서비스 사이트 타입
			@append : 기본 사이트 메인 url 뒤에 붙는 url
		*/
		const onClickExternal = (service: ServiceType, append?: string) => {
			const baseUrl = url[service];
			const fullUrl = append ? `${baseUrl}${append}` : baseUrl;

			// 우측 메뉴바 닫음
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);

			// 메뉴 클릭 시 기존 창으로 페이지 열림
			// play > studio 넘어가는 경우
			if (service === ServiceType.STUDIO) {
				if (token.value && user.value) {
					const param = {
						authorUrl: `${AppConfig.AUTHOR_HOST}author?lang=${loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG)}&destination=main`,
						ctx: JSON.stringify({
							lang: loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG),
							isNew: true,
						}),
					};
					goAuthor(param, storeManager);
				} else window.location.href = fullUrl;
			} else window.location.href = fullUrl; // play > 그 외 서비스
		};

		const updateAuthSession = (token: string) => {
			let msg = '';
			if (token) msg = window.btoa(token);
			let sessionIframe = window.document.getElementById('session-iframe');
			if (!sessionIframe) {
				sessionIframe = window.document.createElement('iframe');
				sessionIframe.setAttribute('id', 'session-iframe');
				const bodyElList = window.document.getElementsByTagName('body');
				if (bodyElList.length > 0) bodyElList[0].appendChild(sessionIframe);
			}
			const targetUrl = `${AppConfig.AUTHOR_HOST}updateSession?a=${msg}&lang=${loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG)}`;
			const iframeWindow = (sessionIframe as HTMLIFrameElement).contentWindow;
			if (iframeWindow) {
				iframeWindow.location.replace(targetUrl);
			}
		};

		const updateUserInfo = () => {
			storeManager.dataStore.addPageApiTotalCount(1);
			getUserInfo(apiClient.value, {})
				.then(res => {
					storeManager.dataStore.addLoadedCount(1);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
					if (res.resultCode === 0) {
						if (res.data) {
							saveLocalData(AppConfig.KEYS.CONST.LOGIN_USER, JSON.stringify(res.data));
							(window as any)?.tracker.setLoginUserId(res.data.userIdx);
							userInfo.value = res.data;
							myMenuOptions.value[0].label = userInfo.value.userNicknm as string;
							updateAuthSession(token.value ?? '');
						}
					} else if (res.resultCode === 91) {
						removeLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN);
						removeLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
						storeManager.dataStore.setAuthToken('');
						updateAuthSession('');
					}
				})
				.catch(e => {
					storeManager.dataStore.addLoadedCount(1);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
				});
		};

		// 이미 로컬스토리지에 user info를 저장하고 있으면 그 정보를 ref 변수에 업데이트
		const loadLocalUserInfo = () => {
			const user = loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
			if (user) userInfo.value = JSON.parse(user);
		};

		// 헤더의 검색창에 입력 후 엔터 클릭 시 무조건 search 페이지로 라우트 이동
		const onEnter = (e: KeyboardEvent): void => {
			if (e.code === 'Enter') {
				router.push({ path: '/search', query: { keyword: keyword.value } });
			}
		};

		const onClickOpenSubMenu = (service: ServiceType) => {
			if (isTabletMenuVisibleType.value === service) isTabletMenuVisibleType.value = undefined;
			else isTabletMenuVisibleType.value = service;
		};
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				e.preventDefault(); // 기본 동작 방지
				router.push({ path: '/search', query: { keyword: keyword.value } });
			}
		};

		const onClickTabletMenu = (value: boolean) => {
			if (value) {
				storeManager.stateStore.setPopupMode(POPUP_MODE.TABLET_SIDE_MENU);
			} else {
				storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
			}
		};

		const openHeaderSearchBar = () => {
			isSearchVisible.value = !isSearchVisible.value;
			storeManager.stateStore.setIsOpenSearchBar(isSearchVisible.value);
		};

		watch(
			() => route.path,
			() => {
				// 페이지 이동 시에 검색바 닫히게
				if (!isSearchPage.value) {
					isSearchVisible.value = false;
					storeManager.stateStore.setIsOpenSearchBar(isSearchVisible.value);
				} else {
					isSearchVisible.value = true;
					storeManager.stateStore.setIsOpenSearchBar(isSearchVisible.value);
					keyword.value = route.query.keyword as string;
				}
			},
		);

		watch(
			() => storeManager.stateStore.popupMode,
			() => {
				isTabletMenuVisibleType.value = curServiceType;
			},
		);

		watch(
			() => storeManager.dataStore.authToken,
			(current, prev) => {
				if (current !== prev) {
					if (current) {
						updateUserInfo();
					} else {
						userInfo.value = null;
						updateAuthSession('');
					}
				}
			},
		);

		watch(
			() => storeManager.commonStore.thirdPartyType,
			() => {
				if (storeManager.commonStore.thirdPartyType === THIRD_PARTY_TYPE.CAFE_24) {
				}
			},
		);

		watch(
			() => keyword.value,
			() => {
				storeManager.dataStore.setSearchKeyword(keyword.value);
			},
		);

		onMounted(() => {
			loadLocalUserInfo();
			// updateUserInfo();
			if (token.value) {
				storeManager.dataStore.setAuthToken(token.value);
				if (AppConfig.ENV === APP_ENV_TYPE.PROD) gtagConfig({ user_id: userInfo.value?.userIdx });
				updateUserInfo();

				nextTick(() => {
					updateUserInfo();
				});
			}
			const loadLang = loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG);
			if (loadLang) {
				lang.value = loadLang;
			}
		});

		onUpdated(() => {
			if (storeManager.dataStore.authToken === '') {
				if (token.value && user) {
					storeManager.dataStore.setAuthToken(token.value);
				}
			}
		});

		return {
			t,
			ssoLogin,
			myMenuOptions,
			lang,
			user,
			userInfo,
			token,
			langOptions,
			ServiceType,
			isSubMenuVisible,
			onClickExternal,
			handleMouseEnter,
			handleMouseLeave,
			handleMouseSubLeave,
			isSearchVisible,
			openHeaderSearchBar,
			keyword,
			isTabletMenuVisibleType,
			onClickOpenSubMenu,
			onClickTabletMenu,
			storeManager,
			POPUP_MODE,
			curServiceType,
			onEnter,
			isSearchPage,
			handleKeyDown,
		};
	},
});
</script>

<template>
	<ClientOnly>
		<header :class="{ open: isSubMenuVisible }">
			<!-- 상단에 고정되는 헤더 -->
			<div class="main-header-wrapper">
				<!-- 좌측 : 로고 -->
				<a class="main-header-logo" href="/">
					<img src="/assets/images/common/logo/main_logo.webp" />
				</a>

				<!-- main menu -->
				<section class="main-menu-section" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
					<ul class="menu-list">
						<li class="menu" @click="onClickExternal(ServiceType.APOC)">{{ t('layout.megaMenu.apoc') }}</li>
						<li class="menu" :class="{ active: curServiceType === ServiceType.PLAY }" @click="onClickExternal(ServiceType.PLAY)">
							{{ t('layout.megaMenu.play') }}
						</li>
						<li class="menu" :class="{ active: curServiceType === ServiceType.STUDIO }" @click="onClickExternal(ServiceType.STUDIO)">
							{{ t('layout.megaMenu.studio') }}
						</li>
						<li class="menu" :class="{ active: curServiceType === ServiceType.ASSET }" @click="onClickExternal(ServiceType.ASSET)">
							{{ t('layout.megaMenu.asset') }}
						</li>
						<li class="menu" :class="{ active: curServiceType === ServiceType.COMM }" @click="onClickExternal(ServiceType.COMM)">
							{{ t('layout.megaMenu.community') }}
						</li>
					</ul>
				</section>

				<!-- 우측 : 언어, 마이페이지/로그인 -->
				<section class="login-section">
					<ul class="menu-list">
						<!-- 검색바 여는 아이콘 -->
						<li @click="openHeaderSearchBar">
							<img class="search-icon" src="/assets/images/common/icons/header/search_2x.webp" />
						</li>
						<!-- 언어 선택 드롭다운 -->
						<li>
							<MegaMenuDropdown class="lang-select" />
						</li>
						<!-- 마이페이지 드롭다운 / 로그인 드롭다운 -->
						<li>
							<header-select v-if="userInfo && token" class="my-page-dropdown" :option-list="myMenuOptions" />
							<span v-else class="my-page-menu login" @click="ssoLogin()">Login</span>
						</li>
						<!-- (tablet ~ mobile) 메뉴 햄버거 버튼 -->
						<li @click="onClickTabletMenu(true)">
							<img class="tab-icon" src="/assets/images/common/icons/header/tab_icon_2x.webp" />
						</li>
					</ul>
				</section>
			</div>

			<!-- (pc ~ tablet) dropdown으로 열리는 검색창 -->
			<transition name="dropdown">
				<section v-show="isSearchVisible" class="mega-search-bar-wrapper pc">
					<div class="mega-search-bar">
						<img src="/assets/images/common/icons/header/search_play.webp" />
						<input v-model="keyword" @keyup="onEnter" @keydown="handleKeyDown" enterkeyhint="go" />
					</div>
				</section>
			</transition>

			<!-- (mobile) 항상 열려있는 검색창 -->
			<section class="mega-search-bar-wrapper mobile">
				<div class="mega-search-bar">
					<img src="/assets/images/common/icons/header/search_play.webp" />
					<input v-model="keyword" @keyup="onEnter" @keydown="handleKeyDown" enterkeyhint="go" />
				</div>
			</section>

			<!-- (pc) dropdown으로 열리는 sub menu -->
			<transition name="dropdown">
				<section v-show="isSubMenuVisible" class="sub-menu-section menu-list" @mouseleave="handleMouseSubLeave">
					<ul class="sub-menu-list menu">
						<li></li>
					</ul>
					<ul class="sub-menu-list menu">
						<li></li>
					</ul>
					<ul class="sub-menu-list menu">
						<li @click="onClickExternal(ServiceType.STUDIO, '')">{{ t('layout.megaMenu.studioList.menu2') }}</li>
						<li @click="onClickExternal(ServiceType.STUDIO, 'price')">{{ t('layout.megaMenu.studioList.menu3') }}</li>
			</ul>
					<ul class="sub-menu-list asset menu">
						<li @click="onClickExternal(ServiceType.ASSET, 'asset-menu?assetType=3D_STICKER')">{{ t('layout.megaMenu.assetList.menu2') }}</li>
						<li @click="onClickExternal(ServiceType.ASSET, 'asset-menu?assetType=REALITY_3D')">{{ t('layout.megaMenu.assetList.menu3') }}</li>
						<li @click="onClickExternal(ServiceType.ASSET, 'asset-menu?assetType=MADE_3D')">{{ t('layout.megaMenu.assetList.menu4') }}</li>
						<li @click="onClickExternal(ServiceType.ASSET, 'asset-menu?assetType=BACKGROUND')">{{ t('layout.megaMenu.assetList.menu5') }}</li>
						<li @click="onClickExternal(ServiceType.ASSET, 'subscribe')">{{ t('layout.megaMenu.assetList.menu6') }}</li>
						<li @click="onClickExternal(ServiceType.ASSET, 'service')">{{ t('layout.megaMenu.assetList.menu7') }}</li>
					</ul>
					<ul class="sub-menu-list community menu">
						<li @click="onClickExternal(ServiceType.COMM, 'board/apoc_news?type=NOW')">{{ t('layout.megaMenu.commList.menu2') }}</li>
						<li @click="onClickExternal(ServiceType.COMM, 'board/tutorial?type=TUTORIAL')">{{ t('layout.megaMenu.commList.menu3') }}</li>
						<li @click="onClickExternal(ServiceType.COMM, 'board/board?type=TIPS')">{{ t('layout.megaMenu.commList.menu4') }}</li>
						<li @click="onClickExternal(ServiceType.COMM, 'board/notice?type=NOTICE')">{{ t('layout.megaMenu.commList.menu5') }}</li>
					</ul>
				</section>
			</transition>
		</header>

		<!-- (tablet ~ mobile) 우측에서 열리는 menu -->
		<transition name="slide">
			<section v-show="storeManager.stateStore.popupMode === POPUP_MODE.TABLET_SIDE_MENU" class="main-menu-section-tablet">
				<ul>
					<li class="close-wrapper">
						<img class="logo-img" src="/assets/images/common/logo/main_logo.webp" />
						<img class="close-btn" src="/assets/images/common/icons/close_icon.webp" @click="onClickTabletMenu(false)" />
					</li>
					<li class="menu" :class="{ active: curServiceType === ServiceType.APOC }" @click="onClickExternal(ServiceType.APOC)">
						{{ t('layout.megaMenu.apoc') }}
					</li>
					<li class="menu" :class="{ active: curServiceType === ServiceType.PLAY }" @click="onClickExternal(ServiceType.PLAY)">
						{{ t('layout.megaMenu.play') }}
					</li>
					
					<li
					class="menu"
					:class="{ opened: isTabletMenuVisibleType === ServiceType.STUDIO, active: curServiceType === ServiceType.ASSET }"
					@click="() => onClickOpenSubMenu(ServiceType.STUDIO)">
					{{ t('layout.megaMenu.studio') }}<ArrowDown />
				</li>
				<transition name="dropdown">
					<section
						v-show="isTabletMenuVisibleType === ServiceType.STUDIO"
						class="sub-menu-tablet"
						:class="{ opened: isTabletMenuVisibleType === ServiceType.STUDIO }">
						<ul>
							<li @click="onClickExternal(ServiceType.STUDIO)">{{ t('layout.megaMenu.studioList.menu1') }}</li>
							<li @click="onClickExternal(ServiceType.STUDIO, 'price')">{{ t('layout.megaMenu.studioList.menu3') }}</li>
						</ul>
					</section>
				</transition>

					<li
						class="menu"
						:class="{ opened: isTabletMenuVisibleType === ServiceType.ASSET, active: curServiceType === ServiceType.ASSET }"
						@click="() => onClickOpenSubMenu(ServiceType.ASSET)">
						asset<ArrowDown />
					</li>
					<transition name="dropdown">
						<section
							v-show="isTabletMenuVisibleType === ServiceType.ASSET"
							class="sub-menu-tablet"
							:class="{ opened: isTabletMenuVisibleType === ServiceType.ASSET }">
							<ul>
								<li @click="onClickExternal(ServiceType.ASSET)">{{ t('layout.megaMenu.assetList.menu1') }}</li>
								<li @click="onClickExternal(ServiceType.ASSET, 'asset-menu?assetType=3D_STICKER')">{{ t('layout.megaMenu.assetList.menu2') }}</li>
								<li @click="onClickExternal(ServiceType.ASSET, 'asset-menu?assetType=REALITY_3D')">{{ t('layout.megaMenu.assetList.menu3') }}</li>
								<li @click="onClickExternal(ServiceType.ASSET, 'asset-menu?assetType=MADE_3D')">{{ t('layout.megaMenu.assetList.menu4') }}</li>
								<li @click="onClickExternal(ServiceType.ASSET, 'asset-menu?assetType=BACKGROUND')">{{ t('layout.megaMenu.assetList.menu5') }}</li>
								<li @click="onClickExternal(ServiceType.ASSET, 'subscribe')">{{ t('layout.megaMenu.assetList.menu6') }}</li>
								<li @click="onClickExternal(ServiceType.ASSET, 'service')">{{ t('layout.megaMenu.assetList.menu7') }}</li>
							</ul>
						</section>
					</transition>

					<li
						class="menu"
						:class="{ opened: isTabletMenuVisibleType === ServiceType.COMM, active: curServiceType === ServiceType.COMM }"
						@click="() => onClickOpenSubMenu(ServiceType.COMM)">
						community<ArrowDown />
					</li>
					<transition name="dropdown">
						<section
							v-show="isTabletMenuVisibleType === ServiceType.COMM"
							class="sub-menu-tablet"
							:class="{ opened: isTabletMenuVisibleType === ServiceType.COMM }">
							<ul>
								<li @click="onClickExternal(ServiceType.COMM)">{{ t('layout.megaMenu.commList.menu1') }}</li>
								<li @click="onClickExternal(ServiceType.COMM, 'board/apoc_news?type=NOW')">{{ t('layout.megaMenu.commList.menu2') }}</li>
								<li @click="onClickExternal(ServiceType.COMM, 'board/tutorial?type=TUTORIAL')">{{ t('layout.megaMenu.commList.menu3') }}</li>
								<li @click="onClickExternal(ServiceType.COMM, 'board/board?type=TIPS')">{{ t('layout.megaMenu.commList.menu4') }}</li>
								<li @click="onClickExternal(ServiceType.COMM, 'board/notice?type=NOTICE')">{{ t('layout.megaMenu.commList.menu5') }}</li>
							</ul>
						</section>
					</transition>
				</ul>
			</section>
		</transition>
	</ClientOnly>
</template>
